import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Horizon Line Yacht Rentals
			</title>
			<meta name={"description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:title"} content={"Horizon Line Yacht Rentals"} />
			<meta property={"og:description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link1" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link3" />
			<Override slot="text" />
			<Override slot="box" />
		</Components.Header1>
		<Section
			padding="130px 0 140px 0"
			background="linear-gradient(0deg,rgba(7, 2, 14, 0.85) 100%,rgba(0,0,0,0) 100%,#5a20ce 100%),#111828 url(https://uploads.quarkly.io/65e71754926d910020edb194/images/1-1%20%281%29.jpg?v=2024-03-05T16:29:20.537Z) 0% 0%/cover"
			color="#000000"
			sm-padding="80px 0 90px 0"
			md-padding="80px 0 80px 0"
			quarkly-title="Statistics-8"
		>
			<Box min-width="100px" min-height="100px" padding="0px 600px 0px 0px" lg-padding="0px 0 0px 0px">
				<Text margin="0px 0px 10px 0px" color="#ffe799" text-transform="uppercase" font="--lead">
					Embark on an Adventure
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" sm-font="normal 600 36px/1.2 --fontFamily-sans">
					The Horizon Line Where Sea Meets Sky
				</Text>
				<Text margin="25px 0px 0px 0px" font="--lead" color="--light">
					Embark on a journey where the waters touch the horizon, offering more than just a trip – it’s an invitation to explore, relax, and create unforgettable memories. Horizon Line Yacht Rentals presents an exclusive fleet of small yachts and motorboats, perfect for sailing on calm waters and discovering hidden gems. Let us be your compass on the way to an extraordinary maritime experience.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box padding="20px 20px 20px 20px" md-width="100%" width="33.333%">
				<Text
					margin="0px 0px 20px 0px"
					letter-spacing="2px"
					color="#cd9f55"
					font="normal 500 20px/1.5 --fontFamily-sansVerdana"
					border-color="#dca654"
				>
					Sail Beyond the Horizon with Us
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline1">
					20+
				</Text>
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					years of experience
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Dive into the essence of marine adventures with Horizon Line Yacht Rentals. Our selection of elegant, comfortable small yachts and motorboats are your keys to unlocking the mysteries of the sea.
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline1" letter-spacing="-.06em" color="--dark">
					50+
				</Text>
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					yachts
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Whether it’s a tranquil solo retreat, a romantic getaway, or an exciting group expedition, we provide the perfect vessel for your journey across the vast blue expanse.
				</Text>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="#111828"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
		>
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="#dca654"
			>
				OUR ADVANTAGES
			</Text>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				Why Choose Horizon Line Yacht Rentals?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					<Strong>
						Personalized Journeys
						<br />
					</Strong>
					Create your sea adventure with our versatile fleet ready to meet all your needs – whether it’s a peaceful afternoon sail or an adventurous island hopping tour.{" "}
					<br />
					<br />
					<Strong>
						Expert Guidance
						<br />
					</Strong>
					Travel with confidence as our experienced crew shares their extensive sea knowledge, ensuring a safe and enjoyable journey.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 0px 0px 25px"
					width="50%"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Strong>
						Seamless Convenience:
						<br />
					</Strong>
					From booking to disembarkation, enjoy a hassle-free experience with our dedicated customer support and onboard amenities.{" "}
					<br />
					<br />
					<Strong>
						Memorable Moments:
						<br />
					</Strong>
					For us, it’s not just about the destination but the journey itself. Capture the beauty of the sea, the warmth of sunsets, and the joy of togetherness.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="#dca654"
				text-align="center"
			>
				OUR MISSION
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				text-align="center"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0 0px 0px 0px" font="--lead" color="#050c32" sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia">
						Horizon Line Yacht Rentals is not just a service, it’s the beginning of a journey. Untethered to a specific region, we have created a universal beacon for those seeking the tranquility of the seas and the thrill of exploration. Our fleet, though diverse, carries a common thread – a promise to deliver experiences that last long after the voyage ends.
					</Text>
					<Button
						margin="1rem 0px 0px 0px"
						background="--color-secondary"
						type="link"
						text-decoration-line="initial"
						href="/about"
					>
						About Us
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Services />
		<Components.Footer23>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link3" />
		</Components.Footer23>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65cddc290072400020ea2467"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});